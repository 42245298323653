/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { FormatDatePipe } from './format-date.pipe';

@Pipe({
  name: 'formatInterval',
})
export class FormatIntervalPipe implements PipeTransform {
  private _datePipe = new FormatDatePipe();

  transform(value: { start: string; end: string }, format = 'dd.MM.yyyy'): unknown {
    return `${this._datePipe.transform(value.start, format)} - ${this._datePipe.transform(value.end, format)}`;
  }
}

@Pipe({
  name: 'parseInterval',
})
export class ParseIntervalPipe<T extends { start: string; end: string }> implements PipeTransform {
  transform(value: T, ...args: unknown[]): T {
    return {
      ...value,
      start: DateTime.fromISO(value.start),
      end: DateTime.fromISO(value.end),
    };
  }
}

@Pipe({
  name: 'parseIntervals',
})
export class ParseIntervalsPipe<T extends { start: string; end: string }> implements PipeTransform {
  transform(value: T[], ...args: unknown[]): T[] {
    return value.map((v) => ({
      ...v,
      start: DateTime.fromISO(v.start),
      end: DateTime.fromISO(v.end),
    }));
  }
}

@Pipe({
  name: 'sortIntervals',
})
export class SortIntervalsPipe<T extends { start: string; end: string }> implements PipeTransform {
  transform(value: T[], ...args: unknown[]): T[] {
    if (!value) return [];
    return value.sort((a, b) => DateTime.fromISO(a.start).toSeconds() - DateTime.fromISO(b.start).toSeconds());
  }
}
