/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';
import { sortBy } from 'lodash-es';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform(value: any[], iteratees: string, direction: 'asc' | 'desc' = 'asc'): any[] {
    const sorted = sortBy(value, iteratees);
    return direction === 'asc' ? sorted : sorted.reverse();
  }
}
