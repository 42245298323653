import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Host,
  Input,
  OnInit,
  Optional,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AbstractFormFieldComponent } from './abstract-form-field.component';

@Component({
  selector: 'app-form-field-input',
  templateUrl: './form-field-input.component.html',
  styleUrls: ['./form-field-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldInputComponent extends AbstractFormFieldComponent implements OnInit {
  @Input()
  textarea = false;

  @Input()
  appearance = 'fill';

  @Input()
  rows = 4;

  @Input()
  help?: string;

  @Input()
  autoFocus = false;

  @ViewChild('inputRef', { read: ElementRef, static: true })
  inputField!: ElementRef<HTMLInputElement>;

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    protected formGroupDirective: FormGroupDirective,
  ) {
    super(formGroupDirective);
  }

  focusInput() {
    setTimeout(() => this.inputField.nativeElement.focus());
  }

  override ngOnInit() {
    super.ngOnInit();
    if (this.autoFocus) this.focusInput();
  }
}
