import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { sortBy } from 'lodash-es';
import { Observable, map, of, shareReplay } from 'rxjs';
import { AgentRepository } from '../../agent.repository';
import { DialogReturnTypeActionEnum } from '../../app.interfaces';
import { ConfigService } from '../../config.service';
import {
  AgentEntity,
  InstanceDto,
  IntervalInputDto,
  InvoiceBunchDto,
  QueryTimelogResult,
  TimelogEntity,
} from '../../generated/api/models';
import { ApiService } from '../../generated/api/services';
import { InvoicingService } from '../../invoicing.service';

export interface TimelogListComponentData {
  interval: IntervalInputDto;
  instance: InstanceDto;
  timelogs?: TimelogEntity[];
}

export class TimelogsListTableDataSource {
  constructor(private readonly timelogs$: Observable<QueryTimelogResult>) {}

  connect(): Observable<readonly TimelogEntity[]> {
    return this.timelogs$.pipe(map((o) => o.nodes));
  }

  disconnect(): void {}

  trackBy(index: number, timelog: TimelogEntity) {
    return `${timelog.id}-${timelog.updatedAt}`;
  }
}

@Component({
  selector: 'app-timelog-list',
  templateUrl: './timelog-list.component.html',
  styleUrls: ['./timelog-list.component.scss'],
})
export class TimelogListComponent implements OnInit {
  timelogs$!: Observable<QueryTimelogResult>;

  DialogReturnTypeActionEnum = DialogReturnTypeActionEnum;

  isDataChanged = false;

  displayedColumns$ = of([
    'agent',
    'dateAndTime',
    // 'description',
    // 'location',
    'seconds',
    'isApproved',
    'isInvoiced',
  ]).pipe(shareReplay());

  tableDataSource?: TimelogsListTableDataSource;

  constructor(
    public readonly matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public readonly data: TimelogListComponentData,
    private readonly dialogRef: MatDialogRef<TimelogListComponent>,
    private readonly api: ApiService,
    private readonly invoicingService: InvoicingService,
    public readonly agentRepo: AgentRepository,
    readonly cs: ConfigService,
  ) {}

  ngOnInit(): void {
    if (this.data.timelogs) {
      this.timelogs$ = of({
        nodes: sortBy(
          this.data.timelogs.map((t) => {
            return {
              ...t,
              invoicingInfo: this.invoicingService.getInvoincingInfo(this.data.instance, t),
              agent: this.agentRepo.getAgentSync(t.agentId) as AgentEntity,
            };
          }),
          'start',
        ),
        total: this.data.timelogs.length,
      });
    } else {
      this.timelogs$ = this.api.timelogQuery({
        body: {
          filter: {
            instanceId: this.data.instance.id,
            interval: {
              start: this.data.interval.start,
              end: this.data.interval.end,
            },
          },
        },
      });
    }

    this.tableDataSource = new TimelogsListTableDataSource(this.timelogs$);
  }

  setTimelogApproved(tl: TimelogEntity, state: boolean) {
    let op$;
    if (state === true) {
      op$ = this.api.timelogApprove({
        body: {
          id: [tl.id],
        },
      });
    } else {
      op$ = this.api.timelogUnapprove({
        body: {
          id: [tl.id],
        },
      });
    }
    op$.subscribe({
      next: () => {
        tl.isApproved = state;
        this.isDataChanged = true;
      },
    });
  }

  onOpenInvoice(invoiceId: string) {
    this.api
      .invoiceBunchSave({
        body: {
          ids: [invoiceId],
        },
      })
      .subscribe({
        next: (res: InvoiceBunchDto) => {
          window.open(`${this.cs.config.apiUrl}/invoice-documentation/i/${res.id}`, 'timelog-documentation');
        },
      });
  }
}
