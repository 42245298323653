<mat-form-field [appearance]="$any(appearance)">
  <mat-label>{{ label | translate }}</mat-label>
  @if (!textarea) {
    <input #inputRef matInput [formControl]="control" type="text" [placeholder]="label | translate" />
  } @else {
    <textarea [rows]="rows" #textareaRef matInput [formControl]="control" type="text" [placeholder]="label | translate">
    </textarea>
  }

  <mat-error>
    @for (error of control.errors | keyvalue | slice: 0 : 1; track error) {
      <div class="icon-with-text">
        <mat-icon color="warn">warning</mat-icon>
        <span>{{ 'Error.' + label + '.' + error.key | translate }}</span>
      </div>
    }
  </mat-error>
</mat-form-field>
