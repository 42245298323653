@switch (mode) {
  @case ('short') {
    {{ agent! | agentIdentifierText }}
  }
  @default {
    <app-avatar [row]="agent!"></app-avatar>
    <div class="name overflow-ellipsis">
      {{ agent! | agentIdentifierText: 'personnelNo' }}
    </div>
    <div class="personnelNo">
      {{ agent! | agentIdentifierText: 'name' }}
      @if (instance?.type) {
        , {{ instance!.type!.name }}
      }
    </div>
  }
}
