import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  selectAllEntities,
  selectEntity,
  setEntities,
  updateEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities';
import { sortBy } from 'lodash-es';
import { Observable, map } from 'rxjs';
import { AgentPermission } from './app.interfaces';
import { AgentEntityDto } from './generated/api/models';

@Injectable()
export class AgentRepository {
  store = this.createStore();

  entities$: Observable<AgentEntityDto[]> = this.store.pipe(selectAllEntities());

  employees$ = this.entities$.pipe(
    map((agents) => agents.filter((a) => a.permissions?.includes(AgentPermission.Employee))),
    map((agents: AgentEntityDto[]) => sortBy(agents, (a) => `${a.nameLast}, ${a.nameFirst}`)),
  );

  constructor() {}

  setAgent(agent: AgentEntityDto[]) {
    this.store.update(setEntities(agent));
  }

  upsertAgent(agent: AgentEntityDto[]) {
    this.store.update(upsertEntities(agent));
  }

  addAgent(agent: AgentEntityDto) {
    this.store.update(addEntities(agent));
  }

  getAgent(id: AgentEntityDto['id']) {
    return this.store.pipe(selectEntity(id));
  }

  getAgentSync(id: AgentEntityDto['id']) {
    return this.store.getValue().entities[id];
  }

  updateAgent(id: AgentEntityDto['id'], agent: Partial<AgentEntityDto>) {
    this.store.update(updateEntities(id, agent));
  }

  deleteAgent(id: AgentEntityDto['id']) {
    this.store.update(deleteEntities(id));
  }

  reset() {
    this.store.reset();
  }

  private createStore(): typeof store {
    const store = createStore({ name: 'agent' }, withEntities<AgentEntityDto>());

    return store;
  }
}
