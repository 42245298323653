import { Component, input } from '@angular/core';

@Component({
  selector: 'app-hero-box-widget',
  templateUrl: './hero-box-widget.component.html',
  styleUrl: './hero-box-widget.component.scss',
})
export class HeroBoxWidgetComponent {
  icon = input();

  text1 = input();
  text2 = input();
  text3 = input();
}
