import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material.module';
import { AgentDisplayComponent, InstanceStaffCustomerComponent } from './agent-display/agent-display.component';
import { AgentIdentifierTextPipe } from './agent-identifier-text.pipe';
import { AgentSearchComponent } from './agent-search/agent-search.component';
import { AvatarComponent } from './avatar/avatar.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DeleteConfirmComponent } from './delete-confirm/delete-confirm.component';
import { FormFieldInputComponent } from './form-field/form-field-input.component';
import { FormatDatePipe, FormatIsoPipe } from './format-date.pipe';
import { FormatDurationPipe } from './format-duration.pipe';
import { FormatIntervalPipe, ParseIntervalPipe, ParseIntervalsPipe, SortIntervalsPipe } from './format-interval.pipe';
import { FormatSecondsPipe } from './format-seconds.pipe';
import { FormatTimelogPipe } from './format-timelog.pipe';
import { HeroBoxWidgetComponent } from './hero-box-widget/hero-box-widget.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { NumberRangeWidgetComponent } from './number-range-widget/number-range-widget.component';
import { ScrollSpyDirective } from './scroll-spy.directive';
import { SortByPipe } from './sort-by.pipe';
import { SortInstanceWageChargesPipe } from './sort-instance-wage-charges.pipe';
import { TimelogListComponent } from './timelog-list/timelog-list.component';

@NgModule({
  declarations: [
    AvatarComponent,
    ScrollSpyDirective,
    TimelogListComponent,
    AgentIdentifierTextPipe,
    AgentDisplayComponent,
    DateRangePickerComponent,
    LoadingIndicatorComponent,
    FormatIntervalPipe,
    ParseIntervalPipe,
    FormatTimelogPipe,
    FormatSecondsPipe,
    InstanceStaffCustomerComponent,
    AgentSearchComponent,
    FormFieldInputComponent,
    DeleteConfirmComponent,
    SortIntervalsPipe,
    ParseIntervalsPipe,
    FormatDatePipe,
    FormatDurationPipe,
    SortByPipe,
    FormatIsoPipe,
    SortInstanceWageChargesPipe,
    HeroBoxWidgetComponent,
    NumberRangeWidgetComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      extend: true,
    }),
    MaterialModule,
    ReactiveFormsModule,
  ],
  exports: [
    SortIntervalsPipe,
    AvatarComponent,
    ScrollSpyDirective,
    ParseIntervalsPipe,
    TranslateModule,
    AgentIdentifierTextPipe,
    AgentDisplayComponent,
    InstanceStaffCustomerComponent,
    DateRangePickerComponent,
    LoadingIndicatorComponent,
    FormatIntervalPipe,
    ParseIntervalPipe,
    FormatTimelogPipe,
    FormatDatePipe,
    FormatSecondsPipe,
    AgentSearchComponent,
    FormFieldInputComponent,
    DeleteConfirmComponent,
    FormatDurationPipe,
    TimelogListComponent,
    SortByPipe,
    FormatIsoPipe,
    SortInstanceWageChargesPipe,
    HeroBoxWidgetComponent,
    NumberRangeWidgetComponent,
  ],
})
export class SharedModule {}
