@if (textLeft(); as textLeft) {
  <div class="text">{{ textLeft }}</div>
}
<div class="bar">
  <div class="bar-left">{{ barTextLeft() }}</div>
  <div class="bar-right">{{ barTextRight() }}</div>
</div>
@if (textRight(); as textRight) {
  <div class="text">{{ textRight }}</div>
}
