/* eslint-disable @angular-eslint/component-selector */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { DateTime, Interval } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { MonthLockEntity } from '../../generated/api/models';

type RenderMonth = {
  caption: string;
  value: DateTime;
  locked?: boolean;
};

@Component({
  selector: 'idv-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, OnChanges {
  @Input()
  value?: string;

  @Input()
  monthsSpan: number = 6;

  @Input()
  locks?: MonthLockEntity[] | null;

  @Input()
  emitInitial = false;

  _disabled = false;

  @Input()
  public set disabled(v: boolean) {
    this._disabled = v;
    v === true ? this.formControl.disable() : this.formControl.enable();
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  formControl = new FormControl<RenderMonth | null>(null);

  @Output()
  selectionChange = new EventEmitter<Interval>();

  renderMonths$ = new BehaviorSubject<RenderMonth[]>([]);

  now!: Date;

  months!: RenderMonth[];

  ngOnInit(): void {
    if (!this.value) {
      this.value = DateTime.utc().startOf('month').toFormat('yyyy-MM-dd');
    }

    const start = DateTime.fromSQL('2016-03-01');
    const end = DateTime.utc().plus({
      months: this.monthsSpan,
    });

    this.months = Interval.fromDateTimes(start, end)
      .splitBy({ months: 1 })
      .map((d) => d.start!.startOf('month'))
      .map((m) => ({
        caption: m.toFormat('LLLL yyyy'),
        value: m,
        locked: undefined,
      }));
    this.renderMonths$.next(this.months);

    const month = this.months.find((o) => o.value.toFormat('yyyy-MM-dd') === this.value);
    if (month) {
      this.formControl.setValue(month);
      if (this.emitInitial) {
        this.selectionChange.emit(Interval.fromDateTimes(month.value.startOf('month'), month.value.endOf('month')));
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['locks']) {
      const locks = changes['locks'];

      if (locks.currentValue) {
        const locked = locks.currentValue.map((l: MonthLockEntity) => l.month);

        this.months = this.months?.map((m) => ({
          caption: m.caption,
          value: m.value,
          locked: locked.includes(m.value.toSQLDate()!.substring(0, 7)),
        }));

        if (this.months) {
          const month: RenderMonth = this.months.find((o) => o.caption === this.formControl.value?.caption)!;
          this.renderMonths$.next(this.months);
          this.formControl.setValue(month);
        }
      }
    }
  }

  selectCompareWith(a: RenderMonth, b: RenderMonth): boolean {
    return a?.caption === b?.caption;
  }

  selectSelectionChange(event: MatSelectChange): void {
    this.selectionChange.emit(
      Interval.fromDateTimes(event.value.value.startOf('month'), event.value.value.endOf('month')),
    );
  }
}
