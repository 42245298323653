<mat-form-field class="hide-ripple-line">
  <mat-select
    [formControl]="formControl"
    #dateSelect
    [compareWith]="selectCompareWith"
    (selectionChange)="selectSelectionChange($event)"
  >
    <mat-select-trigger>
      @if (dateSelect.value?.locked) {
        <mat-icon>lock</mat-icon>
      }
      {{ dateSelect.value?.caption }}
    </mat-select-trigger>

    @for (month of renderMonths$ | async; track month) {
      <mat-option [value]="month">
        @if (month.locked) {
          <mat-icon>lock</mat-icon>
        }
        {{ month.caption }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
