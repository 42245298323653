/* eslint-disable @angular-eslint/component-selector */
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AgentEntity, AgentEntityDto, InstanceDto, InstanceEntity } from '../../generated/api/models';

@Component({
  selector: 'idv-agent-display',
  templateUrl: './agent-display.component.html',
  styleUrls: ['./agent-display.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentDisplayComponent implements OnInit {
  @HostBinding('class')
  @Input()
  mode: 'default' | 'short' = 'default';

  @Input()
  agent!: AgentEntity | AgentEntityDto | null;

  @Input()
  instance?: InstanceDto;

  constructor() {}

  ngOnInit(): void {
    if (!this.agent) {
      throw new Error(`@Input agent is required`);
    }
  }
}

@Component({
  selector: 'idv-instance-staff-display',
  template: `
    @for (staff of row.staff; track staff) {
      <div>
        <idv-agent-display [agent]="staff.agent" />
      </div>
    }
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstanceStaffCustomerComponent {
  @Input()
  row!: InstanceEntity;
}
