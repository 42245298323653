import { Pipe, PipeTransform } from '@angular/core';
import { AgentEntity, AgentEntityDto } from '../generated/api/models';
import { agentIdentifierText } from './agent-identifier-text';

@Pipe({
  name: 'agentIdentifierText',
})
export class AgentIdentifierTextPipe implements PipeTransform {
  transform(
    agent: AgentEntity | AgentEntityDto,
    omit: 'name' | 'personnelNo' | 'none' = 'none',
    separator = ' ',
  ): string {
    return agentIdentifierText(agent, omit, separator);
  }
}
