/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Directive()
export class AbstractFormFieldComponent implements OnInit {
  @Input()
  name!: string;

  @Input()
  control: FormControl | FormGroup | any;

  @Input()
  label!: string;

  error?: string;

  hint?: string;

  constructor(@Optional() @Host() @SkipSelf() protected formGroup: FormGroupDirective) {}

  ngOnInit() {
    if (!this.control && !this.name) {
      throw new Error('@Input() .control or .name must be set');
    }

    if (!this.control) {
      this.control = this.formGroup.form.get(this.name) as FormControl;
    }

    if (!this.control) {
      throw new Error(`Cannot find control with name "${this.name}"`);
    }

    this.hint = this.hint || `${this.label}.hint`;
    this.error = `${this.label}.error`;
  }
}
