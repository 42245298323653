import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AgentEntityDto } from '../../generated/api/models';
import { colorForAgent } from '../../text-color';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements AfterViewInit, OnChanges {
  @Input()
  row!: AgentEntityDto;

  get caption(): string {
    return [this.row.nameFirst || this.row.company, this.row.nameLast]
      .map((s) => (s ? s[0] : undefined))
      .filter((o) => !!o)
      .join('')
      .toUpperCase();
  }

  constructor(private readonly elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['row'] && changes['row'].isFirstChange() === false) {
      this.updateSvgFillColor();
    }
  }

  ngAfterViewInit(): void {
    this.updateSvgFillColor();
  }

  updateSvgFillColor(): void {
    const svgElement = this.elementRef.nativeElement.querySelector('svg');
    if (!this.row.avatar && svgElement && this.caption) {
      svgElement.style = `fill: ${colorForAgent(this.row)}`;
    }
  }
}
