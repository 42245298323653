import { Pipe, PipeTransform } from '@angular/core';
import { InstanceWageChargeDto } from '../generated/api/models';

@Pipe({
  name: 'sortInstanceWageCharges',
})
export class SortInstanceWageChargesPipe implements PipeTransform {
  transform(value: InstanceWageChargeDto[]): InstanceWageChargeDto[] {
    return value.sort((a, b) => (a.start! < b.start! ? -1 : 1));
  }
}
