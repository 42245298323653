import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[scrollSpy]',
})
export class ScrollSpyDirective {
  @Input()
  scrollSpy = 100;

  @Output()
  scrolled = new EventEmitter<number>();

  @HostListener('document:scroll')
  onScroll() {
    this.scrolled.emit(document.scrollingElement!.scrollTop);
  }
}
