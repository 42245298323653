<header mat-dialog-title>
  <div>
    <idv-agent-display [agent]="data.instance.client" class="big"></idv-agent-display>
    <idv-agent-display *ngIf="data.instance.customer" [agent]="data.instance.customer"></idv-agent-display>

    <div *ngIf="data.interval" class="interval">
      {{ data.interval | parseInterval | formatInterval }}
    </div>
  </div>
</header>

<mat-dialog-content *ngIf="tableDataSource">
  <ng-container *ngIf="timelogs$ | async as timelogs">
    <h3>{{ timelogs.nodes.length }} Einträge</h3>
  </ng-container>

  <mat-table class="can-load" [dataSource]="tableDataSource" [trackBy]="tableDataSource.trackBy">
    <ng-container matColumnDef="agent">
      <mat-header-cell *matHeaderCellDef translate="Agent.createType.employee"></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <idv-agent-display [agent]="element.agent"></idv-agent-display>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="dateAndTime">
      <mat-header-cell *matHeaderCellDef>Datum und Uhrzeit</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="date">{{ element.start | date: 'shortDate' }}</div>
        <div class="time">
          {{ element.start | date: 'shortTime' }} -
          {{ element.end | date: 'shortTime' }}
        </div>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef translate="Timelog.description"></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="description">{{ element.description }}</div>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="location">
      <mat-header-cell *matHeaderCellDef translate="Timelog.location"></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="location">{{ element.location }}</div>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="seconds">
      <mat-header-cell *matHeaderCellDef translate="Timelog.seconds"></mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.seconds | formatSeconds }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="isApproved">
      <mat-header-cell *matHeaderCellDef translate="Timelog.isApproved"></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <app-delete-confirm
          buttonType="icon"
          *ngIf="element.isApproved; else notApproved"
          color="primary"
          iconYes="cancel"
          captionYes="Bestätigung entfernen"
          colorYes="accent"
          icon="check_circle"
          (confirmed)="setTimelogApproved(element, false)"
        ></app-delete-confirm>
        <ng-template #notApproved>
          <app-delete-confirm
            buttonType="icon"
            icon="cancel"
            iconYes="check_circle"
            captionYes="Eintrag bestätigen"
            colorYes="primary"
            color="accent"
            (confirmed)="setTimelogApproved(element, true)"
          ></app-delete-confirm>
        </ng-template>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="isInvoiced">
      <mat-header-cell *matHeaderCellDef translate="Timelog.isInvoiced"></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div
          class="clickable"
          *ngIf="element.invoiceId; else invoicingInfo"
          matTooltip="Rechnung öffnen"
          (click)="onOpenInvoice(element.invoiceId)"
        >
          <mat-icon>open_in_new</mat-icon>
        </div>
        <ng-template #invoicingInfo>
          <ng-container *ngIf="element.invoicingInfo; else noInvoicingInfo">
            <div *ngIf="element.invoicingInfo.canInvoiced; else cannotInvoiced">
              <mat-icon
                matTooltip="Dieser Eintrag kann abgerechnet werden"
                color="primary"
                *ngIf="element.invoicingInfo.canInvoiced"
                >thumb_up</mat-icon
              >
            </div>
            <ng-template #cannotInvoiced>
              <div class="icon-with-text">
                <mat-icon color="accent" matTooltip="Nicht abgerechnet">warning</mat-icon>
                <span>{{ element.invoicingInfo.message | translate }}</span>
              </div>
            </ng-template>
          </ng-container>
          <ng-template #noInvoicingInfo>
            <mat-icon color="accent" matTooltip="Nicht abgerechnet">cancel</mat-icon>
          </ng-template>
        </ng-template>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns$ | async; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns$ | async"></mat-row>
    <tr mat-footer-row *matFooterRowDef="displayedColumns$ | async"></tr>
  </mat-table>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="mat-toolbar-spacer"></div>

  <button
    mat-button
    [mat-dialog-close]="{
      action: isDataChanged ? DialogReturnTypeActionEnum.SAVED : DialogReturnTypeActionEnum.OK,
    }"
    translate="Button.caption.close"
  ></button>
</mat-dialog-actions>
