import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDuration',
})
export class FormatDurationPipe implements PipeTransform {
  transform(seconds: number): string {
    const minutes = seconds / 60;
    if (minutes < 91) {
      return `${minutes} Minuten`;
    } else {
      const min = minutes % 60;
      const hours = Math.floor(minutes / 60);

      const hoursStr = hours === 1 ? '1 Stunde' : `${hours} Stunden`;
      const minutesStr = min > 0 ? `${min} Minuten` : '';

      return [hoursStr, minutesStr].join(' ').trim();
    }
  }
}
