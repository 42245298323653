import { Injectable } from '@angular/core';
import { DateTime, Interval } from 'luxon';
import {
  InstanceDto,
  InstancePeriodEntity,
  InstanceWageChargeDto,
  InstanceWageDto,
  TimelogEntity,
} from './generated/api/models';
import { InstanceWageRepository } from './instance-wage.repository';

export type InvoicingInfoResult = {
  timelog: TimelogEntity;
  canInvoiced: boolean;
  message?: string;
  period?: InstancePeriodEntity | null;
  wage?: InstanceWageDto | null;
  charge?: InstanceWageChargeDto | null;
};

@Injectable()
export class InvoicingService {
  constructor(private readonly wageRepo: InstanceWageRepository) {}

  getInstanceWageForId(id: string) {
    return this.wageRepo.getInstanceWageSync(id);
  }

  getInvoincingInfo(instance: InstanceDto, timelog: TimelogEntity): InvoicingInfoResult {
    if (!instance.customer) {
      return {
        timelog,
        canInvoiced: false,
        message: 'Invoicing.instance.noCustomer',
      };
    }

    if (!timelog.isApproved) {
      return {
        timelog,
        canInvoiced: false,
        message: 'Invoicing.timelog.notApproved',
      };
    }

    if (timelog.invoiceId) {
      return {
        timelog,
        canInvoiced: false,
        message: 'Invoicing.timelog.alreadyInvoiced',
      };
    }

    const period = instance.periods?.find((period) => {
      return Interval.fromDateTimes(
        DateTime.fromSQL(period.start).startOf('day'),
        DateTime.fromSQL(period.end).endOf('day'),
      ).contains(DateTime.fromISO(timelog.start));
    });

    if (!period) {
      return {
        timelog,
        canInvoiced: false,
        message: 'Invoicing.timelog.noPeriod',
      };
    }

    if (!period.isConfirmed) {
      return {
        timelog,
        canInvoiced: false,
        message: 'Invoicing.timelog.periodNotConfirmed',
        period,
      };
    }

    const wage = period.wage || instance.wage;
    if (!wage) {
      return {
        timelog,
        canInvoiced: false,
        message: 'Invoicing.timelog.noWage',
        period,
      };
    }

    const charge = wage.charges.find((c) => {
      return Interval.fromDateTimes(
        DateTime.fromSQL(c.start).startOf('day'),
        DateTime.fromSQL(c.end).endOf('day'),
      ).contains(DateTime.fromISO(timelog.start));
    });

    if (!charge) {
      return {
        timelog,
        canInvoiced: false,
        message: 'Invoicing.timelog.noWageCharge',
        period,
        wage,
        charge: null,
      };
    }

    return {
      timelog,
      canInvoiced: true,
      period,
      wage,
      charge,
    };
  }
}
