import { AgentEntity, AgentEntityDto } from '../generated/api/models';

export function agentIdentifierText(
  agent: AgentEntity | AgentEntityDto,
  omit: 'name' | 'personnelNo' | 'none' = 'none',
  separator = ' ',
): string {
  let parts: string[] = [];

  if (omit !== 'name') {
    parts = [agent.company, agent.nameFirst, agent.nameLast];
  }

  /* istanbul ignore else */
  if (omit !== 'personnelNo' && agent.personnelNo) {
    if (parts.length) {
      parts.push(`(${agent.personnelNo})`);
    } else {
      parts.push(agent.personnelNo);
    }
  }

  return parts.filter((element) => element).join(separator);
}
