@if (buttonType === 'button') {
  <button
    mat-stroked-button
    color="warn"
    [matMenuTriggerFor]="menu"
    #menuTrigger
    translate="Button.caption.delete"
  ></button>
} @else {
  <button
    mat-icon-button
    color="warn"
    [matMenuTriggerFor]="menu"
    #menuTrigger
    (menuOpened)="menuOpened.emit()"
    (menuClosed)="menuClosed.emit()"
  >
    <mat-icon [color]="color">{{ icon }}</mat-icon>
  </button>
}

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="confirmed.emit(true)">
    <mat-icon [color]="colorYes">{{ iconYes || icon }}</mat-icon>
    {{ captionYes | translate }}
  </button>
  <button mat-menu-item>
    <mat-icon [color]="color">block</mat-icon>
    {{ captionNo | translate }}
  </button>
</mat-menu>
