import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-delete-confirm',
  templateUrl: './delete-confirm.component.html',
  styleUrls: ['./delete-confirm.component.scss'],
})
export class DeleteConfirmComponent {
  @Input()
  buttonType: 'button' | 'icon' = 'button';

  @Input()
  icon = 'delete';

  @Input()
  iconYes: string | undefined = undefined;

  @Input()
  color: ThemePalette = 'warn';

  @Input()
  colorYes: ThemePalette = 'primary';

  @Input()
  colorNo: ThemePalette = 'warn';

  @Input()
  captionYes = 'Button.caption.deleteConfirm.yes';

  @Input()
  captionNo = 'Button.caption.deleteConfirm.no';

  @Output()
  confirmed = new EventEmitter<boolean>();

  @Output()
  menuClosed = new EventEmitter<void>();

  @Output()
  menuOpened = new EventEmitter<void>();
}
