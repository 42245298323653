/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Component, computed, input } from '@angular/core';

@Component({
  selector: 'app-number-range-widget',
  templateUrl: './number-range-widget.component.html',
  styleUrl: './number-range-widget.component.scss',
  host: {
    '[class.has-left-text]': 'textLeft()',
    '[class.has-right-text]': 'textRight()',
    '[style.--percentage]': 'barTemplatePercentage()',
  },
})
export class NumberRangeWidgetComponent {
  /** Percentage value. Must be number between 0 and 1 */
  value = input.required<number>();

  /** Left outer text (optional) */
  textLeft = input<string>();
  /** Right outer text (optional) */
  textRight = input<string>();

  /** Left inner text (optional) */
  barTextLeft = input<string>();
  /** Right inner text (optional) */
  barTextRight = input<string>();

  barTemplatePercentage = computed(() => `${Math.round(this.value() * 100)}%`);
}
