<div matAutocompleteOrigin #origin="matAutocompleteOrigin">
  <mat-form-field>
    <button mat-icon-button matPrefix [disabled]="true">
      <mat-icon>search</mat-icon>
    </button>
    @if (inputElement.value) {
      <button mat-icon-button matSuffix (click)="autocompleteInputControl.reset()">
        <mat-icon>close</mat-icon>
      </button>
    }

    <input
      #inputElement
      (keydown.enter)="enterPressed.emit(autocompleteInputControl.value)"
      matInput
      [placeholder]="placeholder"
      [formControl]="autocompleteInputControl"
      [matAutocomplete]="auto"
      [matAutocompleteConnectedTo]="origin"
    />

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
      @if (searchResults$ | async; as suggestionGroups) {
        @for (suggestionGroup of suggestionGroups; track $index) {
          <mat-optgroup [label]="'AgentPermission.' + suggestionGroup.label | translate">
            @for (option of suggestionGroup.options; track option.id) {
              <mat-option [value]="option">
                <idv-agent-display [agent]="option"></idv-agent-display>
              </mat-option>
            }
          </mat-optgroup>
        }
      }
    </mat-autocomplete>
  </mat-form-field>
</div>
