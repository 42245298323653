import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { groupBy, intersection } from 'lodash-es';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AgentPermission, AutocompleteSuggestionGroup } from '../../app.interfaces';
import { AgentEntity, AgentEntityDto } from '../../generated/api/models';

@Component({
  selector: 'app-agent-search',
  templateUrl: './agent-search.component.html',
  styleUrls: ['./agent-search.component.scss'],
})
export class AgentSearchComponent implements OnInit, AfterViewInit {
  @Input()
  placeholder = 'Suche';

  @Input()
  autoFocus = false;

  @Input()
  groupBy = undefined;

  @Input({ required: true })
  agents$?: Observable<AgentEntity[] | AgentEntityDto[]>;

  @Input()
  control?: FormControl | FormArray;

  @Output()
  optionSelected = new EventEmitter<AgentEntity>();

  @Output()
  enterPressed = new EventEmitter<string>();

  @ViewChild('inputElement', { read: ElementRef, static: true })
  inputElement!: ElementRef<HTMLInputElement>;

  searchResults$?: Observable<AutocompleteSuggestionGroup<AgentEntity>[]>;

  autocompleteInputControl = new FormControl<string>('', { nonNullable: true });

  ngOnInit(): void {
    if (!this.agents$) {
      throw new Error(`@Input agents must be set`);
    }
    this.searchResults$ = combineLatest([
      this.autocompleteInputControl.valueChanges.pipe(startWith(null)),
      this.agents$,
    ]).pipe(
      map(([input, availableAgents]) => this.filterAgentsFn(input, availableAgents as AgentEntity[]).slice(0, 100)),
    );
  }

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      this.focusInput();
    }
  }

  focusInput() {
    setTimeout(() => this.inputElement.nativeElement.focus());
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.optionSelected.emit(event.option.value);
    this.control?.setValue(event.option.value);
    this.autocompleteInputControl.reset();
  }

  filterAgentsFn(input: string | null | undefined, agents: AgentEntity[]): AutocompleteSuggestionGroup<AgentEntity>[] {
    const labels = [
      AgentPermission.Employee,
      AgentPermission.Client,
      AgentPermission.Customer,
      AgentPermission.Admin,
      AgentPermission.Other,
    ];

    const filteredAgents = agents.filter((o) => {
      // Allow anyone to pass for no or object input to show full list initially
      if (!input || typeof input !== 'string') {
        return true;
      }
      return [o.nameFirst, o.nameLast, o.personnelNo].join(' ').toLocaleLowerCase().includes(input.toLocaleLowerCase());
    });

    const groupedAgents = groupBy(filteredAgents, (agent: AgentEntity) => {
      const interse = intersection(labels, agent.permissions);
      return interse[0] ?? AgentPermission.Other;
    });

    return Object.keys(groupedAgents)
      .map((label) => ({
        label,
        options: groupedAgents[label],
      }))
      .sort((a, b) => labels.indexOf(a.label as AgentPermission) - labels.indexOf(b.label as AgentPermission));
  }
}
