import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  selectAllEntities,
  selectEntity,
  setEntities,
  updateEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities';
import { Observable } from 'rxjs';
import { InstanceWageDto } from './generated/api/models';

@Injectable()
export class InstanceWageRepository {
  store = this.createStore();

  entities$: Observable<InstanceWageDto[]> = this.store.pipe(selectAllEntities());

  constructor() {}

  setInstanceWage(wage: InstanceWageDto[]) {
    this.store.update(setEntities(wage));
  }

  upsertInstanceWage(wage: InstanceWageDto[]) {
    this.store.update(upsertEntities(wage));
  }

  addInstanceWage(wage: InstanceWageDto) {
    this.store.update(addEntities(wage));
  }

  getInstanceWage(id: InstanceWageDto['id']) {
    return this.store.pipe(selectEntity(id));
  }

  getInstanceWageSync(id: InstanceWageDto['id']) {
    return this.store.getValue().entities[id];
  }

  updateInstanceWage(id: InstanceWageDto['id'], wage: Partial<InstanceWageDto>) {
    this.store.update(updateEntities(id, wage));
  }

  deleteInstanceWage(id: InstanceWageDto['id']) {
    this.store.update(deleteEntities(id));
  }

  reset() {
    this.store.reset();
  }

  private createStore(): typeof store {
    const store = createStore({ name: 'instance-wage' }, withEntities<InstanceWageDto>());

    return store;
  }
}
