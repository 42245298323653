import { AgentEntityDto } from './generated/api/models';

export const colors = [
  'mediumaquamarine',
  'cornflowerblue',
  'palevioletred',
  'midnightblue',
  'mediumvioletred',
  'orchid',
  'teal',
  'greenyellow',
  'darkorange',
  'fuchsia',
  'darkgreen',
  'slateblue',
  'blue',
  'darkblue',
  'turquoise',
  'palegreen',
  'indianred',
  'plum',
  'lightgreen',
  'magenta',
  'dodgerblue',
  'darkslateblue',
  'darkseagreen',
  'seagreen',
  'red',
  'springgreen',
  'darkkhaki',
  'lightcoral',
  'crimson',
  'darkviolet',
  'mediumblue',
  'aquamarine',
  'orangered',
  'aqua',
  'olive',
  'steelblue',
  'mediumseagreen',
  'yellow',
  'cadetblue',
  'indigo',
  'lightseagreen',
  'darkolivegreen',
  'mediumorchid',
  'mediumturquoise',
  'deeppink',
  'mediumslateblue',
  'blueviolet',
  'khaki',
  'gold',
  'coral',
  'olivedrab',
  'darksalmon',
  'chartreuse',
  'mediumspringgreen',
  'deepskyblue',
  'cyan',
  'skyblue',
  'orange',
  'green',
  'purple',
  'darkorchid',
  'darkturquoise',
  'darkred',
  'forestgreen',
  'limegreen',
  'navy',
  'yellowgreen',
  'firebrick',
  'lime',
  'royalblue',
  'pink',
  'mediumpurple',
  'violet',
  'lawngreen',
  'darkcyan',
  'salmon',
  'tomato',
  'hotpink',
  'lightsalmon',
  'darkmagenta',
];

export function colorForText(text: string): string {
  const sum = text.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return colors[sum % colors.length];
}

export function colorForAgent(agent: AgentEntityDto): string {
  const text = [agent.nameFirst || agent.company, agent.nameLast]
    .map((s) => (s ? s[0] : undefined))
    .filter((o) => !!o)
    .join('')
    .toUpperCase();

  return colorForText(text);
}
