import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  transform(value: DateTime | string, format = 'dd.MM.yyyy'): unknown {
    const dt = value instanceof DateTime ? value : DateTime.fromSQL(value, { zone: 'utc' });
    return dt.toFormat(format);
  }
}

@Pipe({
  name: 'formatIso',
})
export class FormatIsoPipe implements PipeTransform {
  transform(value: string, format = 'dd.MM.yyyy'): unknown {
    const dt = DateTime.fromISO(value, { zone: 'utc' });
    return dt.toFormat(format);
  }
}
