/* eslint-disable @typescript-eslint/naming-convention */

import { AgentEntity } from './generated/api/models';

export interface UIData<T> {
  entries?: T[];
  active: T;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type DialogData = {};

export type DialogReturnType<T> = {
  action: DialogReturnTypeActionEnum;
  data: T;
};

// eslint-disable-next-line no-shadow
export enum DialogReturnTypeActionEnum {
  CANCEL,
  OK,
  SAVED,
  DELETED,
}

export type AutocompleteSuggestion = {
  groupLabel: string;
  agents: AgentEntity[];
};

export type AutocompleteSuggestionGroup<T = string> = {
  label: string;
  options: T[];
};

export enum AgentPermission {
  Admin = 'Admin', // Root!
  Employee = 'Employee', // Allow agent to be member of a team and be related to projects
  Client = 'Client', // Allow agent to be member of a team and be related to projects
  Customer = 'Customer', // Allow agent to be member of a team and be related to projects
  Other = 'Other', // Allow agent to be member of a team and be related to projects
  // ACCOUNTANT = 'ACCOUNTANT', // Allow agent to see amounts/charges and create invoices
}

export enum SettingsName {
  DescriptionTemplates = 'DescriptionTemplates',
  LocationTemplates = 'LocationTemplates',
  SuspendedInstances = 'SuspendedInstances',
}

export type ChangelogEntry = {
  version?: string;
  affected: string[];
  title: string;
  description: string;
  date: string;
};
