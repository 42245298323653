import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatSeconds',
})
export class FormatSecondsPipe implements PipeTransform {
  transform(seconds: number): string {
    const absSeconds = Math.abs(seconds);
    const hours: number = Math.floor(absSeconds / 3600);
    const minutes: number = Math.ceil((absSeconds % 3600) / 60);
    const displayMinutes = ('' + minutes).padStart(2, '0');
    return `${seconds < 0 ? '-' : ''}${hours}:${displayMinutes}`;
  }
}
