import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TimelogEntity } from '../generated/api/models';

@Pipe({
  name: 'formatTimelog',
})
export class FormatTimelogPipe implements PipeTransform {
  private _datePipe = new DatePipe('de');

  transform(value: TimelogEntity): string | null {
    try {
      return `${this._datePipe.transform(
        value?.start,
        'shortTime',
      )} - ${this._datePipe.transform(value?.end, 'shortTime')}`;
    } catch (error) {
      console.error('value', value);
      return null;
    }
  }
}
